<template>
  <div id="vital1" ref="vital1" class="news_content">
    <div class="index1"></div>
    <div class="index2">
      <div class="head">
        <div class="box"></div>
        <div class="title">{{ newsDetail.title }}</div>
        <div class="box">
          <div class="back" @click="goBack">返回</div>
        </div>
      </div>

      <div class="info">
        <div>发布日期：{{ newsDetail.createDate }}</div>
        <div>信息来源：{{ newsDetail.cityName }}</div>
      </div>
      <div class="line"></div>
      <div v-html="newsDetail.content" class="news_detail"></div>
    </div>
  </div>
</template>
<script>
import { getNewsDetail } from "@/api/home";
export default {
  name: "NewsDetail",
  components: {},
  data() {
    return {
      id: null,
      newsDetail: {},
    };
  },
  created() {},
  mounted() {
    this.goDetail();
  },
  methods: {
    goDetail() {
      this.id = this.$route.params.id;
      getNewsDetail({ id: this.id }).then((res) => {
        this.newsDetail = res.result;
        this.newsDetail.content = this.newsDetail.content.replaceAll(
          "&nbsp;",
          ""
        );
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.news_content {
  background-color: rgba(248, 248, 248, 100);
  padding-bottom: 50px;
}
.index1 {
  height: 70px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/news_bg.png")
    no-repeat center center;
  background-size: 100% 100%;
  text-align: left;
  background-color: white;
  // padding-left: 340px;
  // padding-right: 660px;
}
.index2 {
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 70px;
  width: 1500px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  padding: 40px 40px 60px 40px;
  // border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 100);
  display: flex;
  flex-direction: column;
  .head {
    display: flex;
  }
  .box {
    flex: 1;
  }
  .back {
    width: 96px;
    height: 40px;
    background: linear-gradient(90deg, #ffd799 0%, #ff9c00 100%);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    float: right;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .title {
    margin-bottom: 25px;
    width: 1000px;
    font-size: 32px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  .info {
    padding: 0 400px;
    display: flex;
    justify-content: space-between;

    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 23px;
  }
  .line {
    width: 1420px;
    height: 1px;
    background: #c1c1c1;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  ::v-deep .news_detail {
    color: rgba(51, 51, 51, 1);
    text-align: left;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 29px;
    text-indent: 36px;
    img {
      width: 50% !important;
      height: auto !important;
      display: block !important;
      margin: 0 auto;
    }
    br {
      display: none;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
